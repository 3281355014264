import {
  isArray,
  flatMap,
  map,
  keys,
  isPlainObject,
  concat,
  pickBy,
  Dictionary,
  identity,
  isNil,
} from 'lodash';

// See https://stackoverflow.com/a/36490174/82609
export function paths(obj: any, parentKey?: string): string[] {
  var result: string[];
  if (isArray(obj)) {
    var idx = 0;
    result = flatMap(obj, function (obj: any) {
      return paths(obj, (parentKey || '') + '[' + idx++ + ']');
    });
  } else if (isPlainObject(obj)) {
    result = flatMap(keys(obj), function (key) {
      return map(paths(obj[key], key), function (subkey) {
        return (parentKey ? parentKey + '.' : '') + subkey;
      });
    });
  } else {
    result = [];
  }
  return concat(result, parentKey || []);
}

// TS only utils to overcome bad typing on Object.keys/Object.values and the missing Exact<> type
// See https://github.com/microsoft/TypeScript/issues/31652
export const getRecordKeys = <K extends string>(
  record: Partial<Record<K, any>>,
) => (Object.keys(record) as any) as K[];
export const getRecordValues = <V>(record: Partial<Record<any, V>>) =>
  Object.values(record) as V[];

export const getAllWithIds = <T extends { id: string }>(
  list: T[],
  ids: string[],
): T[] => {
  return list.filter((item) => ids.includes(item.id));
};

// export const pickNonNil = <T extends object>(object: T): Partial<T> =>
//   pickBy(object, v => !isNil(v));
